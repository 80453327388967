import { CCApiService } from './api-service';
import { UIConfig, ServiceAccount, ServiceAccountForm  } from './interfaces';

export class ServiceAccountsService {
  static ApiService: CCApiService;
  static serviceAccountsAPI: string;
  static introspectionAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.introspectionAPI = `${this.apiBasePath}/introspection`;
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts`;
  }

  static async getServiceAccount(id): Promise<ServiceAccount> {
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${id}`).then(entities => {
      return entities;
    });
  }

  static async getServiceAccounts(filter: {}): Promise<any> {
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}?${new URLSearchParams(filter)}`).then(entities => {
      return entities;
    });
  }

  static async createServiceAccount(serviceAccount: ServiceAccountForm): Promise<ServiceAccount> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(serviceAccount),
    };
    return this.ApiService.performFetch(this.serviceAccountsAPI, fetchOptions).then(entity => {
      return entity;
    });
  }

  static async updateServiceAccount(serviceAccount: ServiceAccountForm): Promise<ServiceAccount> {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(serviceAccount),
    };
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${serviceAccount.id}`, fetchOptions).then(entity => {
      return entity;
    });
  }

  static async deleteServiceAccount(id: string): Promise<{}> {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${id}`, fetchOptions).then(entity => {
      return entity;
    });
  }

  static async getScopes(): Promise<{scopes: [{name: string, description: string}]}> {
    return this.ApiService.performFetch(`${this.introspectionAPI}/scopes`).then(entities => {
      return entities;
    });
  }

  static async getCredentials(serviceAccountId: string): Promise<{clientId: string, clientSecret: string}> {
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${serviceAccountId}/credentials`).then(entities => {
      return entities;
    });
  }
}
